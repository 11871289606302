import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Currency from "../components/currency"
import SSLFooter from "../components/ssl_footer"
import { Card, Cards, CardHeadData, CardData } from "../components/cards"
import useCheckout from "../hooks/use-checkout"

const handleClear = (clearCart: () => void) => async (e: React.MouseEvent) => {
  e.preventDefault()
  clearCart()
}

const handleRemove =
  (removeItem: (sku: string) => void, sku: string) => (e: React.MouseEvent) => {
    e.preventDefault()
    removeItem(sku)
  }

const handleChangeQuantity =
  (
    setItem: (sku: string, signed: string, count: number) => void,
    sku: string,
    signed: string
  ) =>
  (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const newQuantity = parseInt(e.target.value)
    setItem(sku, signed, newQuantity)
  }

const Basket = () => {
  const { clearCart, items, slugToImage, removeItem, setItem, subTotal } =
    useCheckout()

  return (
    <Fragment>
      <table className="hidden md:table w-full mb-2 bg-gray-100 border border-solid border-gray-800 border-collapse checkoutTable">
        <thead>
          <tr className="bg-gray-400 border-0 border-b border-solid border-b-gray-800 border-collapse">
            <th className="p-1" />
            <th className="p-1">Title</th>
            <th className="p-1">Quantity</th>
            <th className="p-1 text-right">Price(each)</th>
            <th className="p-1 text-right">Price(total)</th>
            <th className="p-1" />
          </tr>
        </thead>
        <tbody>
          {items.map((item, i: number) => {
            const image = slugToImage[`${item.category}-${item.name}`]
            return (
              <tr key={i}>
                <td className="cell-collapse">
                  {image && <Img className="shadow-md" fixed={image} />}
                </td>
                <td>{item.name}</td>
                <td>
                  <input
                    className="w-full text-right"
                    type="number"
                    name={item.sku}
                    inputMode="numeric"
                    pattern="[0-9]*"
                    min="0"
                    max="99"
                    value={item.quantity}
                    onChange={handleChangeQuantity(
                      setItem,
                      item.sku,
                      item.signed
                    )}
                  />
                </td>
                <td className="text-right">
                  <Currency amount={item.price} />
                </td>
                <td className="text-right">
                  <Currency amount={item.price.mult(item.quantity)} />
                </td>
                <td className="w-40">
                  <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleRemove(removeItem, item.sku)}
                  >
                    Remove Item
                  </button>
                </td>
              </tr>
            )
          })}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={3} />
            <td className="font-bold text-right">Sub Total</td>
            <td className="font-bold text-right">
              <Currency amount={subTotal} />
            </td>
            <td />
          </tr>
        </tfoot>
      </table>
      <Cards className="flex flex-col md:hidden">
        {items.map((item, i: number) => {
          const image = slugToImage[`${item.category}-${item.name}`]
          return (
            <Card key={i} className="my-1">
              {image && (
                <Img
                  className="shadow-md w-full flex-grow w-full"
                  fixed={image}
                />
              )}
              <CardHeadData name="Title">{item.name}</CardHeadData>
              <CardHeadData name="Quantity">
                <input
                  type="number"
                  name={item.sku}
                  min="0"
                  max="99"
                  value={item.quantity}
                  onChange={handleChangeQuantity(
                    setItem,
                    item.sku,
                    item.signed
                  )}
                />
              </CardHeadData>
              <CardHeadData name="Price(each)">
                <Currency amount={item.price} />
              </CardHeadData>
              <CardHeadData name="Price(total)">
                <Currency amount={item.price.mult(item.quantity)} />
              </CardHeadData>
              <CardData>
                <button
                  className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleRemove(removeItem, item.sku)}
                >
                  Remove Item
                </button>
              </CardData>
            </Card>
          )
        })}
        <Card>
          <CardHeadData name="Sub Total">
            <Currency amount={subTotal} />
          </CardHeadData>
        </Card>
      </Cards>
      {items && items.length ? (
        <Fragment>
          <button
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-1 md:mr-2 my-1"
            onClick={handleClear(clearCart)}
          >
            Clear Basket
          </button>
          <Link
            to="/checkout"
            className="bg-bm-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-1 inline-block md:ml-2 my-1"
          >
            Checkout
          </Link>
        </Fragment>
      ) : (
        <div />
      )}
      <SSLFooter />
    </Fragment>
  )
}

export default Basket
